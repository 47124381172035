<template>
  <div>
    <div v-if="is_edit" class="px-2">
      <p class="text-sm font-semibold">{{ $t('FIELD_ADDRESS') }}:</p>
      <v-text tag="p" class="text-xs py-1" option_key="contactPage.addressMap" :default_value="address"></v-text>
    </div>
    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe
            class="gmap_iframe"
            width="100%"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            :src="`https://maps.google.com/maps?width=600&amp;height=600&amp;hl=en&amp;q=${address}&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const is_edit = inject('is_edit')
const address = ref(
    getDataGlobalByKey(useRoute().params.langCode, 'TEXT__contactPage.addressMap', 'contact')?.option_value
)
</script>
<style>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
}

.gmap_iframe {
  height: 600px !important;
}
</style>
