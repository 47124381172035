<template>
  <section class="pb-10">
    <div class="container pt-10 xl:pt-20 overflow-hidden">
      <div data-aos="zoom-out" data-aos-once="true" class="text-primary text-sm lg:text-2xl font-semibold">
        <global-heading
            title="Nội dung bạn cần liên hệ là gì "
            opt-key-title="contactPage.titleContentContact"
        ></global-heading>
      </div>
      <div
          data-aos="fade-up"
          data-aos-once="true"
          class="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 max-w-lg md:max-w-full mx-auto"
      >
        <wrapper-content
            @isOpen="isPopup = $event"
            v-for="(item, index) in listContent"
            :index="index + 1"
            :title="item.title"
            :content="item.content"
            :textLink="item.textLink"
            :link="item.link"
        />
      </div>
    </div>
  </section>
  <popup v-if="isPopup" :data="dataSubmit" @closeOTP="isPopup = false"/>
</template>
<script lang="ts" setup>
import WrapperContent from './__WrapperContent.vue'
import Popup from './__Popup.vue'

const {t} = useI18n()
const isPopup = ref(false)

const listContent = [
  {
    icon: '/images/contact/comment.png',
    title: 'Tư vấn bảo hiểm',
    content:
        'Chúng tôi kết nối nhu cầu của khách hàng với nghiệp vụ bảo hiểm của đối tác để tạo ra sản những phẩm bảo hiểm tối ưu',
    textLink: t('CONTACT_NOW'),
    link: '#'
  },
  {
    icon: '/images/contact/camera.png',
    title: 'Báo chí / Hợp tác',
    content:
        'Chúng tôi kết nối nhu cầu của khách hàng với nghiệp vụ bảo hiểm của đối tác để tạo ra sản những phẩm bảo hiểm tối ưu',
    textLink: t('CONTACT_NOW'),
    link: '#sectionContact'
  },
  {
    title: 'Hỗ trợ ứng dụng',
    content:
        'Chúng tôi kết nối nhu cầu của khách hàng với nghiệp vụ bảo hiểm của đối tác để tạo ra sản những phẩm bảo hiểm tối ưu',
    textLink: t('CONTACT_NOW'),
    link: t('FAQ_INDEX_URL')
  }
]
</script>
