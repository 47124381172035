<template>
  <section class="lg:pt-20 pt-10">
    <div class="relative container mb-10 lg:mb-29">
      <div class="flex flex-col lg:flex-row gap-8">
        <div
            data-aos="fade-right"
            data-aos-duration="300"
            data-aos-once="true"
            class="w-full lg:w-5/12 text-2xl lg:text-3xl xl:text-35px font-[550] lg:mt-0 mt-10 lg:leading-10"
        >
          <v-content
              default_value="Nội dung liên hệ khác? Hãy để lại tin nhắn,chúng tôi sẽ phản hồi!"
              option_key="contactPage.contentContact"
          ></v-content>
        </div>
        <div class="w-full flex flex-col lg:flex-row gap-5">
          <form
              @submit.prevent="sendFormContact"
              data-aos="fade-up-right"
              data-aos-once="true"
              class="form-submit w-full relative px-5 lg:px-13.5 pt-8 lg:pt-12 pb-10 lg:pb-17.5 rounded-20px bg-shadow"
          >
            <!-- <div class="absolute inset-0 -z-1">
            <img src="/images/about/bg-form-contact.png" alt="" class="w-full h-full object-cover" />
            </div> -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-3 lg:gap-y-6">
              <div class="relative z-1">
                <label
                    for="name_contact_page"
                    :class="errorName ? '!text-error-01' : ''"
                    class="text-bw-26 font-semibold text-13px block pb-2 uppercase"
                >{{ $t('FIELD_NAME') }}*</label
                >
                <input
                    id="name_contact_page"
                    name="name"
                    type="text"
                    v-model="infoContact.name"
                    :class="errorName ? '!placeholder:text-error-01/80 border-error-01' : ''"
                    class="bg-white w-full rounded-28px py-15px px-5.5 focus:ring-0 b-1 b-solid b-[#CBCBCB] capitalize h-13.5 flex items-center"
                    :placeholder="$t('ENTER_YOUR_FIRST_AND_LAST_NAME')"
                    @blur="checkName()"
                />
                <Transition name="fade">
                                    <span class="text-error-01 pl-5 mt-2" v-if="errorName">
                                        {{ $t('PLEASE_ENTER_YOUR_NAME') }}
                                    </span>
                </Transition>
              </div>
              <div class="relative z-1">
                <label
                    for="email_contact_page"
                    :class="errorEmail ? '!text-error-01' : ''"
                    class="text-bw-26 font-semibold text-13px block pb-2 uppercase"
                >
                  Email*
                </label>
                <input
                    id="email_contact_page"
                    name="email"
                    type="email"
                    v-model="infoContact.email"
                    :class="
                                        errorEmail ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''
                                    "
                    class="bg-white w-full rounded-28px py-15px px-5.5 b-1 b-solid b-[#CBCBCB] h-13.5 flex items-center"
                    :placeholder="$t('INPUT_EMAIL')"
                    @blur="checkEmail"
                />
                <Transition name="fade">
                                    <span class="text-error-01 pl-5 mt-2" v-if="errorEmail">
                                        {{ $t('PLEASE_ENTER_A_VALID_EMAIL') }}
                                    </span>
                </Transition>
              </div>
              <div class="relative z-1">
                <label
                    for="phone_number_contact_page"
                    :class="errorPhone ? '!text-error-01' : ''"
                    class="text-bw-26 text-13px font-semibold block pb-2 uppercase"
                >{{ $t('FIELD_PHONE') }}*</label
                >
                <input
                    id="phone_number_contact_page"
                    name="phone"
                    type="text"
                    v-model="infoContact.phone"
                    :class="
                                        errorPhone ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''
                                    "
                    class="bg-white w-full rounded-28px py-15px px-5.5 b-1 b-solid b-[#CBCBCB] h-13.5 flex items-center"
                    :placeholder="$t('ENTER_YOUR_PHONE_NUMBER')"
                    @input="convertedPhoneNumber()"
                    @blur="checkPhone()"
                />
                <Transition name="fade">
                                    <span class="text-error-01 pl-5 mt-2" v-if="errorPhone">
                                        {{ $t('INCORRECT_PHONE_NUMBER') }}
                                    </span>
                </Transition>
              </div>
              <div class="relative z-5">
                <label
                    for="option_contact_contact_page"
                    class="text-bw-26 text-13px font-semibold block pb-2 uppercase"
                >
                  {{ $t('CONSULTING_NEEDS') }}
                </label>
                <global-select
                    :data="dataConsult"
                    v-model:senData="infoContact.consult"
                    class="rounded-full h-13.5 flex items-center"
                ></global-select>
              </div>
            </div>
            <div class="flex flex-col md:flex-row gap-x-3 mt-6">
              <div class="relative z-1 w-full md:w-[65%]">
                <label
                    for="consult_contact_page"
                    :class="errorNote ? '!text-error-01' : ''"
                    class="text-bw-26 text-13px font-semibold block pb-2 uppercase"
                >
                  {{ $t('LEAVE_MESSAGE') }}
                </label>

                <textarea
                    id="consult_contact_page"
                    name="consult"
                    rows="1"
                    cols="50"
                    v-model="infoContact.note"
                    :class="
                                        errorNote ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''
                                    "
                    class="bg-white w-full rounded-28px py-15px md:py-14px px-5.5 b-1 b-solid b-[#CBCBCB] flex items-center h-13.5 custom-scrollbar-global"
                    :placeholder="$t('ENTER_MESSENGE')"
                    @blur="checkNote()"
                ></textarea>
                <Transition name="fade">
                                    <span class="text-error-01 pl-5 mt-2" v-if="errorNote">
                                        {{ $t('PLEASE_ENTER_A_MESSAGE') }}
                                    </span>
                </Transition>
              </div>
              <div class="relative z-1 flex-1 md:mt-0 mt-5">
                <label
                    for="consult_contact_page"
                    class="text-bw-26 text-13px font-semibold block pb-2 uppercase invisible md:block hidden"
                >
                  {{ $t('LEAVE_MESSAGE') }}
                </label>
                <GlobalVerifyForm
                    :error="errorChecked"
                    v-model:checked="isChecked"
                    class="!m-0 flex justify-center !mb-3 !lg:mb-5 md:hidden"
                />
                <button
                    class="flex-1 relative btn-submit-contact bg-accent-01 lg:bg-primary text-white font-semibold uppercase text-sm block px-11.5 rounded-30px py-4.5 flex items-center justify-center w-full h-13.5 flex items-center"
                    :class="successSubmit ? '' : 'pointer-events-none'"
                >
                  <p v-if="successSubmit">{{ $t('SUBMIT') }}</p>
                  <div v-else class="absolute loaderForm"></div>
                </button>

                <Transition name="slide-fade">
                  <div
                      v-if="statusContact"
                      class="absolute -bottom-15 lg:-bottom-20 xl:-bottom-15 right-0 bg-white shadow-lg py-3 px-4 rounded-lg flex items-center gap-2 z-10 w-full md:w-max"
                  >
                    <div class="flex gap-2 items-center flex-1">
                      <div
                          class="w-5 h-5 bg-green-600 flex flex-col items-center justify-center rounded-full"
                          v-if="!isErorr"
                      >
                                                <span
                                                    class="text-base font-medium i-ic:baseline-check text-xs text-white"
                                                ></span>
                      </div>
                      <div
                          class="w-5 h-5 bg-error-01 flex flex-col items-center justify-center rounded-full"
                          v-else
                      >
                                                <span
                                                    class="text-base font-medium i-custom-close2 w-2.5 h-2.5 text-white"
                                                ></span>
                      </div>

                      <span v-if="!isErorr" class="text-sm flex-1">
                                                {{ $t('SUCCESSFULLY_SENT') }}
                                            </span>

                      <span v-else class="text-sm flex-1">
                                                {{ $t('SENDING_REQUEST_FAILED') }}
                                            </span>
                    </div>
                    <div
                        @click="statusContact = false"
                        class="w-4 h-4 bg-gray-200 hover:bg-gray-300 flex flex-col items-center justify-center rounded-full transform translate-x-2 -translate-y-2 duration-200"
                    >
                      <span class="i-custom-close2 text-6px text-bw-24 cursor-pointer"></span>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>

            <GlobalVerifyForm
                :error="errorChecked"
                v-model:checked="isChecked"
                class="mt-5 hidden md:block"
            />
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts" setup>
import {useReCaptcha} from 'vue-recaptcha-v3'
import {
  CUSTOMER_SUPPORT_CONTACT_EVENT_ID,
  useEventTracking
} from "../../../composables/ackee/event";

const {t} = useI18n()
const props: any = defineProps({
  dataCategory: {
    type: Array
  }
})
const dataConsult = [
  {
    id: t('SERVICE_REFLECTION'),
    content: t('SERVICE_REFLECTION')
  },
  {
    id: t('APPLICATION_SUPPORT'),
    content: t('APPLICATION_SUPPORT')
  },
  {
    id: t('COMMUNICATION_COLLABORATION'),
    content: t('COMMUNICATION_COLLABORATION')
  },
  {
    id: t('ORTHER'),
    content: t('ORTHER')
  }
]
const infoContact: any = ref({
  lang: useRoute().params.langCode,
  name: '',
  email: '',
  phone: '',
  consult: '',
  note: ''
})

const successSubmit = ref(true)
const recaptchaInstance = useReCaptcha()
const recaptcha = async () => {
  // optional you can await for the reCaptcha load
  await recaptchaInstance?.recaptchaLoaded()

  // get the token, a custom action could be added as argument to the method
  const token = await recaptchaInstance?.executeRecaptcha('login')

  return token
}

const errorPhone = ref(false)
const errorName = ref(false)
const errorEmail = ref(false)
const errorNote = ref(false)
const errorChecked = ref(false)
const isChecked = ref()

const convertedPhoneNumber = () => {
  if (infoContact.value.phone !== '' && infoContact.value.phone[0] === '0') {
    var convertedPhoneNumber = infoContact.value.phone?.substring(1)
    infoContact.value.phone = '+84' + convertedPhoneNumber
  }
}
const checkPhone = () => {
  var vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g
  if (infoContact.value.phone) {
    var convertedPhoneNumber = infoContact.value.phone.replace(/^\+84/, '0')
    if (vnf_regex.test(convertedPhoneNumber)) {
      errorPhone.value = false
    } else {
      errorPhone.value = true
    }
  } else {
    errorPhone.value = true
  }
}

const checkName = () => {
  if (infoContact.value.name) {
    errorName.value = false
  } else {
    errorName.value = true
  }
}

const checkEmail = () => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  if (emailRegex.test(infoContact.value.email)) {
    errorEmail.value = false
  } else {
    errorEmail.value = true
  }
}
const checkNote = () => {
  if (infoContact.value.note) {
    errorNote.value = false
  } else {
    errorNote.value = true
  }
}

const checkChecked = () => {
  if (isChecked.value) {
    errorChecked.value = false
  } else {
    errorChecked.value = true
  }
}

watch(
    () => isChecked.value,
    () => {
      checkChecked()
    }
)

const statusContact: any = ref(false)
const isErorr = ref(false)

const sendFormContact = async () => {
  checkPhone()
  checkName()
  checkEmail()
  checkChecked()
  checkNote()

  useEventTracking(CUSTOMER_SUPPORT_CONTACT_EVENT_ID, infoContact.value.consult)
  if (!errorName.value && !errorPhone.value && !errorEmail.value && !errorChecked.value && !errorNote.value) {
    successSubmit.value = false
    infoContact.value.name = capitalizeFirstLetterOfWords(infoContact.value.name)
    const token = await recaptcha()
    fetch(`/api/contact?response=${token}`, {
      method: 'POST',
      body: JSON.stringify(infoContact.value)
    })
        .then((response) => response.json())
        .then((data) => {
          successSubmit.value = true
          if (data.code == 201) {
            statusContact.value = true
            isErorr.value = false
            setTimeout(() => {
              statusContact.value = false
            }, 1500)
            infoContact.value = {
              lang: useRoute().params.langCode,
              name: '',
              email: '',
              phone: '',
              consult:
                  props?.dataCategory && props?.dataCategory.length > 0 && props?.dataCategory[0]?.id
                      ? props?.dataCategory[0]?.id
                      : '',
              note: ''
            }
          } else {
            statusContact.value = true
            isErorr.value = true
            setTimeout(() => {
              statusContact.value = false
            }, 1500)
          }
        })
        .catch((error) => {
          successSubmit.value = true
        })
  }
}
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  display: none;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.bg-shadow {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
</style>
