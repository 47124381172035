<template>
  <div class="relative aspect-480/134">
    <div class="absolute inset-0 z-1 bg-wrapper overflow-hidden">
      <div class="relative w-full h-full">
        <div class="absolute top-0 -left-30 h-full">
          <img src="/images/circle/hinh4.svg" class="object-cover w-full h-full" alt="Banner Pattern"/>
        </div>
        <div class="hidden md:block absolute top-30 -right-100 h-full">
          <img src="/images/circle/hinh10.svg" class="object-cover w-full h-full" alt="Banner Pattern"/>
        </div>
      </div>
    </div>
    <div class="relative z-10 pt-15 xl:pt-40 pb-10 xl:pb-24 container">
      <div data-aos="fade-right" data-aos-once="true" class="text-white/60 text-sm font-semibold leading-6">
        {{ $t('CONTACT') }}
      </div>
      <div class="leading-10 md:leading-17 text-4xl md:text-[56px] font-[550] text-white mt-3.5">
        <v-content
            data-aos="fade-right"
            data-aos-once="true"
            default_value="IZIon24 luôn sẵn sàng tiếp đón"
            option_key="contactPage.contentBannerHeader"
        ></v-content>
      </div>
      <div data-aos="fade-up" data-aos-once="true" class="mt-8.5 space-y-1">
        <p class="flex items-center gap-1 text-white/90">
          <span class="i-mdi-clock text-base md:text-lg flex-none"></span>
          <span class="uppercase text-xs md:text-sm leading-4.5">{{ $t('WORKING_TIME') }}</span>
        </p>
        <v-content
            class="font-[550] text-base md:text-xl text-white"
            tag="p"
            default_value="Thứ Hai – Thứ Sáu, 9AM – 6.30PM"
            option_key="contactPage.timeBannerHeader"
        ></v-content>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
.bg-wrapper {
  background: radial-gradient(247.42% 100.06% at 32.37% 100%, #ffa17d 5.03%, #ff8050 42.75%, #ed5a22 100%);
}
</style>
